@import 'the-color';

// Colors
$color-variations: (
  noir: #283333,
  blanc: #ffffff,

  main: #F7F0EB,
  alt: #686E63,

  social: (
    /**
     * Brand colors
     *
     * @see https://brandcolors.net/
     */
    defaut: #9f9f9f,
    facebook: #3b5998,
    twitter: #1da1f2,
    google-plus: #dd4b39,
    linkedin: #0077b5,
    vimeo: #1ab7ea,
    youtube: #cd201f,
    instagram: unquote('radial-gradient(circle at 30% 100%, #fed276, rgba(253, 189, 97, 0.5) 9%, #f6804d 20%, rgba(232, 61, 92, 0)), linear-gradient(166deg, #4e60d3, #913baf 14%, #d52d88 76%, #f26d4f)')
  )
);

$custom_ease: cubic-bezier(0.37, 0.75, 0, 1);

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/inter-thin.woff2') format('woff2'),
       url('../../fonts/inter-thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/inter-light.woff2') format('woff2'),
       url('../../fonts/inter-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/inter-regular.woff2') format('woff2'),
       url('../../fonts/inter-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/inter-medium.woff2') format('woff2'),
       url('../../fonts/inter-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Syne';
  src: url('../../fonts/syne-regular.woff2') format('woff2'),
       url('../../fonts/syne-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Syne';
  src: url('../../fonts/syne-bold.woff2') format('woff2'),
       url('../../fonts/syne-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

$title: 'Syne';