.nav {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    background: color(blanc);
    font-family: $title;
    font-weight: 400;

    &__burger {
        display: none;
        background: transparent;
        cursor: pointer;

        @include tablette {
            display: block;
        }

        span {
            display: block;
            width: 20px;
            height: 1px;
            margin: 4px 0 0 0;
            transition: transform 0.3s ease-out, opacity 0.3s ease-out;
            background: color(noir);

            &:nth-child(3) {
                margin: -1px 0 0 0;
            }
        }
    }

    &__container {
        display: flex;
        align-items: center;
        
        @include tablette {
            display: none;
            position: absolute;
            top: 64px;
            left: 0;
            width: 100%;
            background: color(blanc);
        }
    }

    &__language {
        display: flex;
        margin: 0 0 0 20px;

        @include tablette {
            display: flex;
            justify-content: center;
            margin: 10px 0;
        }

        &Item {
            display: block;
            padding: 8px;
        }
    }

    &__list {
        display: flex;

        @include tablette {
            display: block;
        }

        &Link {
            display: flex;
            position: relative;
            align-items: center;
            height: 100%;
            padding: 12px 60px;
            color: color(noir);
            font-size: 1.6rem;
            text-align: center;
            line-height: 1.25;

            &--colored {
                background: color(noir);
                color: color(blanc);
            }

            @include tablette {
                justify-content: center;
            }
        }

        &Item {
            position: relative;

            &:not(:first-child) {
                .nav__listLink {
                    &:not(.nav__listItem--colored) {
                        &::before {
                            content: '';
                            position: absolute;
                            top: 8px;
                            left: 0;
                            width: 1px;
                            height: calc(100% - 16px);
                            background: color(noir);
                            opacity: 0.3;
                        }
                    }
                }
            }

            @include desktop {
                &:hover {
                    .nav__listSub {
                        opacity: 1;
                        transform: translate(0, 1px);
                        pointer-events: all;
                    }
                }
            }

            &.js-open {
                .nav__listSub {
                    display: block;
                }
            }
        }

        &Sub {
            position: absolute;
            top: 100%;
            left: 0;
            min-width: 100%;
            padding: 12px 0;
            background: color(blanc);
            opacity: 0;
            transform: translate(0, -20px);
            pointer-events: none;
            transition: transform 0.85s $custom_ease, opacity 0.3s ease-out;

            @include tablette {
                display: none;
                position: relative;
                top: auto;
                opacity: 1;
                transform: none;
                pointer-events: all;
            }

            &Item {
                &:not(:first-child) {
                    &::before {
                        content: '';
                        display: block;
                        width: calc(100% - 80px);
                        margin: 0 auto;
                        height: 1px;
                        background: color(noir);
                        opacity: 0.2;
                    }
                }
            }

            &Link {
                display: block;
                padding: 10px 40px;
                color: color(noir);
                font-size: 1.5rem;
                white-space: nowrap;

                @include tablette {
                    text-align: center;
                }
            }
        }
    }

    &__logo {
        display: block;
        padding: 5px 0;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &.js-open {
        .nav__burger {
            span {
                &:nth-child(1),
                &:nth-child(4) {
                    opacity: 0;
                }

                &:nth-child(2) {
                    transform: rotate(45deg);
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                }
            }
        }

        .nav__container {
            display: block;
        }
    }
}

.aside {
    padding: 10px 0;
    background: color(main);
    font-size: 1.5rem;
    font-weight: 400;
    font-style: italic;

    &__img {
        display: block;
        width: 100px;
        margin: 0 30px 0 0;
        mix-blend-mode: multiply;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            text-align: center;
        }
    }
}