@mixin tablette() {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

@mixin mobile() {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin desktop() {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}