html {
    scroll-behavior: smooth;
    scroll-padding-top: 64px;
}

body {
    width: 100vw;
    overflow: hidden;
    padding-top: 64px;
    color: color(noir);
    font-family: 'Inter';
    font-size: 1.6rem;
    font-weight: 300;
}

.container {
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    padding: 0 20px;

    &--small {
        max-width: 770px;
    }
}

.title {
    font-family: $title;
    text-align: left;
    line-height: 1;

    strong {
        font-weight: 700;
    }

    &--h1 {
        font-size: 5rem;

        @include mobile {
            font-size: 3.5rem;
        }
    }

    &--h2 {
        font-size: 3.2rem;
        font-weight: 700;

        @include mobile {
            font-size: 2.3rem;
        }
    }

    &--h3 {
        font-size: 2.4rem;

        @include mobile {
            font-size: 1.8rem;
        }
    }
}

.subtitle {
    font-family: $title;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-description,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group-desc,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-table-header,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy span,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td {
    color: color(noir) !important;
    font-family: 'Inter' !important;
    font-size: 1.6rem !important;
    font-weight: 300 !important;
}

#ot-sdk-cookie-policy .ot-sdk-cookie-policy.ot-sdk-container #cookie-policy-title,
#ot-sdk-cookie-policy .ot-sdk-cookie-policy.ot-sdk-container .ot-sdk-cookie-policy-group {
    font-family: $title !important;
    font-size: 1.2rem !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    letter-spacing: unset !important;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table th {
    background: color(main) !important;
}

[data-youtube],
[data-sound],
[data-video] {
    cursor: pointer;
}

.youtube {
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 11;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translate(0, 10%);
    pointer-events: none;
    transition: opacity 0.3s ease-out, transform 0.85s $custom_ease;

    &.js-open {
        opacity: 1;
        transform: translate(0, 0);
        pointer-events: all;
    }

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        cursor: pointer;

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 1px;
            background: color(blanc);
            transform: translate(-50%, -50%) rotate(45deg);

            &:first-child {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }

    &__overlay {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: color(noir);
        opacity: 0.8;
        cursor: pointer;
    }

    &__iframe {
        position: relative;
        aspect-ratio: 16/9;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.sound,
.videopopin {
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 11;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translate(0, 10%);
    pointer-events: none;
    transition: opacity 0.3s ease-out, transform 0.85s $custom_ease;

    &.js-open {
        opacity: 1;
        transform: translate(0, 0);
        pointer-events: all;
    }

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        cursor: pointer;

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 1px;
            background: color(blanc);
            transform: translate(-50%, -50%) rotate(45deg);

            &:first-child {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }

    &__overlay {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: color(noir);
        opacity: 0.8;
        cursor: pointer;
    }

    &__player {
        position: relative;

        audio,
        video {
            display: block;
            max-width: 100%;
            margin: 0 auto;
        }
    }
}

.footer {
    padding: 10px 0;
    background: color(alt);

    .container {
        position: relative;
    }

    &__link {
        display: block;
        padding: 15px !important;
        border: 0 !important;
        background: transparent !important;
        color: color(blanc) !important;
        font-size: 1.4rem !important;
        cursor: pointer;

        @include tablette {
            padding: 5px 15px;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include tablette {
            display: block;
            margin-top: 10px;
            text-align: center;
        }
    }

    svg,
    &__image {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        max-width: 200px;
        transform: translate(0, -50%);

        @include tablette {
            display: block;
            position: relative;
            top: auto;
            margin: 0 auto;
            transform: none;
        }
    }
}