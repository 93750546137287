// Styles Onepoint

#divpopup {
    border-radius: 5px;
}

.close {
    position: absolute;
    right: 23px;
    top: 10px;
    width: 16px;
    height: 16px;
    opacity: 0.3;
    border: none;
    background: unset;
}

.close:hover {
    opacity: 1;
}

.close:before,
.close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: #333;
}

.close:before {
    transform: rotate(45deg);
}

.close:after {
    transform: rotate(-45deg);
}

.filter-button:hover {
    background-color: white;
    color: black;
}

.filter-button {
    color: white;
    background: #004a88;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    font-size: 8pt;
    padding: 0;
    margin: 5px;
    border: 3px solid #00000080;
}

.text-font {
    font-family: sans-serif;
}

button.text-font {
    background: #004a88;
    color: white;
    font-weight: lighter;
    border: none;
    padding: 10px;
    border-radius: 5px;
    margin-left: 5px;
    margin-right: 5px;
}