.header {
    background: color(main);

    &__cite {
        padding: 0 0 0 20px;
        border-left: 1px solid color(alt);
        font-weight: 500;
    }

    &__col {
        position: relative;
        width: 100%;

        @include desktop {
            width: 50%;
        }

        &--content {
            padding: 20px 20px 0 20px;

            @include desktop {
                padding: 40px 30px;
            }
        }

        &--image {
            height: 580px;

            @include tablette {
                height: auto;
            }
        }
    }

    &__content {
        margin: 20px 0 0 0;
        padding: 0 0 40px 0;
        text-align: justify;

        @include desktop {
            padding: 0 0 0 50px;
        }

        p {
            &:not(:first-child) {
                margin-top: 20px;
            }
        }
    }

    &__deco {
        position: absolute;
        top: -10px;
        right: 0;
        width: 100%;
    }

    &__img {
        width: 100%;
        object-fit: cover;

        @include desktop {
            position: absolute;
            top: 0;
            right: 0;
            width: 50vw;
            max-width: none;
            height: 100%;
        }
    }

    &__wrapper {
        display: flex;
        position: relative;
        z-index: 1;
        flex-wrap: wrap;
        align-items: center;
    }
}

.app {
    padding: 40px 0;

    @include desktop {
        padding: 120px 0 80px;
    }

    &__close {
        right: -60px !important;
        left: auto !important;
        width: 40px !important;
        height: 40px !important;
        cursor: pointer;

        @include mobile {
            top: -50px !important;
            right: 10px !important;
        }

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 1px;
            background: color(blanc);
            transform: translate(-50%, -50%) rotate(45deg);

            &:first-child {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }

    &__col {
        width: 100%;

        @include desktop {
            width: calc(100% / 3 * 1);

            &--large {
                width: calc(100% / 3 * 2);
            }
        }
    }

    &__content {
        padding: 40px 0 0 0;

        @include desktop {
            padding: 0 0 0 60px;
        }
    }

    &__cta {
        display: inline-flex;
        align-items: center;
        margin: 30px 0 0 0;
        padding: 10px 20px;
        background: color(noir);
        color: color(blanc);
        font-family: $title;
        font-size: 1.2rem;
        font-weight: 500;
        cursor: pointer;

        svg {
            display: block;
            width: 20px;
            height: auto;
            margin: 0 0 0 10px;
            transform: translate(0, 1px);
        }
    }

    &__img {
        width: 100%;
    }

    &__module {
        width: 100%;
    }

    &__popin {
        display: flex;
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: rgba(color(noir), 0.8);
        transition: opacity 0.3s ease-out, transform 0.85s $custom_ease;

        &Container {
            position: relative;
            width: 100%;
            max-width: 600px;
            height: 90vh;
            background: color(blanc);

            @include mobile {
                height: 80vh;
            }

            & > div {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        &:not(.js-visible) {
            opacity: 0;
            transform: translate(0, 10%);
            pointer-events: none;
        }
    }

    &__text {
        margin: 20px 0 0 0;
        text-align: justify;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
}

.cite {
    padding: 20px 0 40px;

    @include desktop {
        padding: 20px 0 60px;
    }

    &__content {
        font-weight: 500;
        text-align: center;
    }

    &--soft {
        padding: 40px 0;
        background: color(main);

        @include desktop {
            padding: 60px 0;
        }
    }

    .title {
        text-align: center;
    }
}

.temoignage {
    padding: 40px 0;

    @include desktop {
        padding: 80px 0 40px;
    }

    &__col {
        width: 100%;

        @include desktop {
            width: calc(50% - 15px);
        }

        @include tablette {
            &:first-child {
                margin-bottom: 40px;
            }
        }
    }

    &__content {
        text-align: justify;

        p {
            &:not(:first-child) {
                margin-top: 20px;
            }
        }
    }

    &__podcast {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px 0 0 0;
        padding: 0 20px;
        background: color(noir);
    }

    &__video {
        position: relative;
        margin: 0 0 20px 0;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: color(noir);
            opacity: 0.3;
            mix-blend-mode: multiply;
        }

        svg {
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        img {
            width: 100%;
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 30px;
    }

    .title--h3 {
        padding: 0 0 10px 45px;
        background: url('../../img/subtitle.svg') left 20px center no-repeat;
        background-size: 9px auto;
    }
}

.heading {
    padding: 40px 0;
    overflow: hidden;
    background: color(noir);

    @include desktop {
        padding: 60px 0;
    }

    &__deco {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -45%);
    }

    .title {
        color: color(blanc);
    }

    &--dash {
        border-top: 40px solid color(blanc);
    }
}

.investigation {
    padding: 20px 0;

    @include desktop {
        padding: 40px 0;
    }

    &__col {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 20px 0;
        column-gap: 30px;

        @include desktop {
            padding: 40px 0;
        }

        &Item {
            width: 100%;

            @include desktop {
                width: calc(50% - 15px);
            }

            &--figure {
                display: flex;
                flex-wrap: wrap;
                column-gap: 30px;
                margin: 30px 0 0 0;

                @include desktop {
                    margin: 0;
                }
            }

            @include tablette {
                &:not(:first-child) {
                    .investigation__video {
                        margin-top: 30px;
                    }
                }
            }
        }
    }

    &__content {
        text-align: justify;

        p {
            &:not(:first-child) {
                margin: 20px 0 0 0;
            }
        }
    }

    &__figure {
        width: calc(50% - 15px);

        &Item {
            padding: 20px;
            background: color(noir);
            color: color(blanc);
            text-align: center;
        }

        &Content {
            font-size: 1.2rem;
        }

        &Number {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 5px 0;
            font-family: $title;
            font-size: 2.2rem;
            font-weight: 700;
            line-height: 1;

            span {
                font-size: 5rem;
                font-weight: 400;
                line-height: 6rem;
            }
        }

        &Img {
            position: relative;
            margin: 20px 0 0 0;
            aspect-ratio: 255/306;

            &:first-child {
                margin: 0 0 20px 0;
                aspect-ratio: 255/289;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__intro {
        display: flex;
        padding: 20px 0;

        @include desktop {
            padding: 40px 0;
        }

        @include tablette {
            flex-wrap: wrap;
        }

        &Content {
            text-align: justify;
        }

        svg {
            display: block;
            flex: 1 0 100%;
            height: auto;
            margin: 0 0 20px 0;

            @include desktop {
                flex: 0 0 250px;
                margin: 0 30px 0 0;
            }
        }
    }

    &__label {
        margin: 0 0 0 20px;
        padding: 0 0 0 15px;
        border-left: 1px solid color(noir);
        font-size: 1.2rem;
    }

    &__video {
        cursor: pointer;

        img {
            width: 100%;
            margin: 0 0 20px 0;
            aspect-ratio: 540/300;
            object-fit: cover;
        }
    }
}

.figure {
    background: color(noir);
    color: color(blanc);

    &__col {
        width: 100%;

        @include desktop {
            width: 50%;
        }

        &--content {
            display: flex;
            align-items: center;
        }
    }

    &__content {
        padding: 30px 20px;
        text-align: center;

        @include desktop {
            padding: 30px 30px 30px 15px;
        }

        .title {
            text-align: center;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;

        &Item {
            width: 100%;
            margin: 0 0 30px 0;
            padding: 0 15px;

            @include desktop {
                width: calc(100% / 3 * 1);

                &--large {
                    width: calc(100% / 3 * 2);
                }

                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(7),
                &:nth-child(8) {
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 1px;
                        height: 60px;
                        background: color(alt);
                    }
                }
            }

            &:not(.figure__list--border) {
                @include tablette {
                    &:nth-child(1) {
                        order: 0;
                    }

                    &:nth-child(2) {
                        order: 1;
                    }

                    &:nth-child(3) {
                        order: 2;
                    }

                    &:nth-child(4) {
                        order: 3;
                    }

                    &:nth-child(5) {
                        order: 5;
                    }

                    &:nth-child(6) {
                        order: 4;
                    }

                    &:nth-child(7) {
                        order: 6;
                    }

                    &:nth-child(8) {
                        order: 7;
                    }
                }
            }


            &--text {
                display: flex;
                align-items: center;
                justify-content: center;
                
                @include desktop {
                    margin-top: 30px;
                }
            }
        }

        &Content {
            font-size: 1.2rem;

            &--large {
                font-size: 1.6rem;
            }
        }

        &Number {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 5px 0;
            font-family: $title;
            font-size: 2.2rem;
            font-weight: 700;
            line-height: 1;

            span {
                font-size: 5rem;
                font-weight: 400;
                line-height: 6rem;
            }
        }

        &--border {
            .figure__list {
                &Item {
                    &:nth-child(5),
                    &:nth-child(6) {
                        position: relative;

                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 1px;
                            height: 60px;
                            background: color(alt);

                            @include tablette {
                                content: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        max-height: 600px;
        object-fit: cover;

        @include desktop {
            width: 50vw;
            max-width: none;
            float: right;
        }
    }

    &__text {
        margin: 30px 0 15px 0;

        @include desktop {
            padding: 0 65px;
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
    }
}

.testimony {
    position: relative;
    padding: 40px 0;

    @include desktop {
        padding: 80px 0;
    }

    &__blockquote {
        margin: 30px 0 0 0;

        &Author {
            font-family: $title;
            font-size: 1.2rem;
            font-weight: 700;
            text-transform: uppercase
        }

        &Position {
            display: block;
            margin: 10px 0 0 0;
            font-family: 'Inter';
            font-weight: 300;
            font-style: normal;
            text-transform: none;
        }

        &Text {
            margin: 0 0 30px 0;
            padding: 0 0 0 20px;
            border-left: 1px solid color(noir);
            font-size: 1.5rem;
            text-align: justify;
            line-height: 1.6;
        }
    }

    &__content {
        position: relative;
        width: 100%;
        padding: 30px 20px 0;
        background: color(blanc);

        @include desktop {
            width: 50%;
            padding: 60px 95px;
        }
    }

    &__deco {
        position: absolute;
        top: -15px;
        left: 50px;

        @include desktop {
            left: 90px;
        }
    }

    &__img {
        width: 100%;

        @include desktop {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            object-fit: cover;
        }
    }
}

.faq {
    padding: 40px 0;
    background: color(main);

    @include desktop {
        padding: 60px 0;
    }

    .title {
        margin: 0 0 10px 0;
        text-align: center;
    }

    &__content {
        padding: 0 0 20px 0;
        text-align: justify;
    }

    &__item {
        border-bottom: 1px solid color(noir);
        font-size: 1.4rem;

        &.js-open {
            .faq__title {
                &::after {
                    transform: translate(0, -50%) rotate(180deg);
                }
            }
        }
    }

    &__title {
        position: relative;
        padding: 20px 0;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            width: 12px;
            height: 12px;
            background: url('../../img/illustration/faq.svg') center center no-repeat;
            transition: transform 0.85s $custom_ease;
            transform: translate(0, -50%);
        } 
    }

    &__wrapper {
        height: 0;
        overflow: hidden;
    }
}

.social {
    padding: 40px 0;

    @include desktop {
        padding: 80px 0;
    }

    &__col {
        width: 100%;

        @include desktop {
            width: calc(50% - 15px);
        }
    }

    &__content {
        text-align: justify;

        p {
            line-height: 1.6;

            &:not(:first-child) {
                margin-top: 20px;
            }
        }
    }

    &__label {
        margin: 0 0 0 20px;
        padding: 0 0 0 15px;
        border-left: 1px solid color(noir);
        font-size: 1.2rem;
    }

    &__video {
        position: relative;
        margin: 0 0 20px 0;
        cursor: pointer;

        @include tablette {
            margin-top: 20px;
        }

        &Img {
            width: 100%;
        }

        svg {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 30px;
    }
}

.podcast {
    margin: 40px 0 0 0;
    padding: 40px 0;
    background: color(noir);
    color: color(blanc);

    @include desktop {
        margin: 70px 0 0 0;
        padding: 60px 0;
    }

    &__col {
        width: 100%;

        @include desktop {
            width: calc(50% - 15px);
        }
    }

    &__img {
        margin: -80px auto 20px;
        cursor: pointer;

        @include desktop {
            margin: -130px auto -60px;
        }
    }

    &__subtitle {
        margin: 0 0 10px 0;
        color: color(alt);
        font-family: $title;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 1.6;
        text-transform: uppercase;
    }

    &__text {
        margin: 20px 0 0 0;
        text-align: justify;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;
    }
}

.all {
    padding: 40px 0;

    @include desktop {
        padding: 80px 0;
    }

    &__col {
        width: 100%;

        @include desktop {
            width: calc(50% - 15px);
        }
    }

    &__content {
        text-align: justify;

        @include desktop {
            margin-top: 65px;
        }

        p {
            &:not(:first-child) {
                margin-top: 20px;
            }
        }
    }

    &__figure {
        display: flex;
        align-items: flex-end;
        column-gap: 30px;

        @include tablette {
            margin-top: 20px;
        }

        &Item {
            width: 100%;

            @include desktop {
                width: calc(40% - 15px);

                &--large {
                    width: calc(60% - 15px);
                }
            }

            &--figure {
                padding: 10px 20px 20px 20px;
                background: color(noir);
                color: color(blanc);
                text-align: center;
            }
        }

        &Content {
            font-size: 1.2rem;
        }

        &Number {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 5px 0;
            font-family: $title;
            font-size: 2.2rem;
            font-weight: 700;
            line-height: 1;

            span {
                font-size: 5rem;
                font-weight: 400;
                line-height: 6rem;
            }
        }

        &Img {
            width: 100%;
        }

        &Label {
            margin: 20px 0 0 20px;
            padding: 0 0 0 15px;
            border-left: 1px solid color(noir);
            font-size: 1.2rem;

            strong {
                font-weight: 500;
            }
        }
    }

    &__video {
        margin: 30px 0 0 0;

        &Img {
            width: 100%;
            cursor: pointer;
        }

        &Label {
            margin: 20px 0 0 20px;
            padding: 0 0 0 15px;
            border-left: 1px solid color(noir);
            font-size: 1.2rem;

            strong {
                font-weight: 500;
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        // align-items: center;
        column-gap: 30px;
    }
}

.application {
    padding: 40px 0;

    @include desktop {
        padding: 80px 0;
    }

    &__col {
        width: 100%;

        @include desktop {
            width: calc(50% - 15px);
        }
    }

    &__content {
        text-align: justify;

        p {
            &:not(:first-child) {
                margin-top: 20px;
            }
        }
    }

    &__cta {
        display: inline-flex;
        align-items: center;
        padding: 10px 20px;
        background: color(noir);
        color: color(blanc);
        font-family: $title;
        font-size: 1.2rem;
        font-weight: 500;
        cursor: pointer;

        svg {
            display: block;
            width: 20px;
            height: auto;
            margin: 0 0 0 10px;
            transform: translate(0, 1px);
        }
    }

    &__img {
        width: 100%;
        margin: 40px 0;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 30px;
    }
}

.reaction {
    padding: 0 0 40px 0;

    @include desktop {
        padding: 0 0 80px 0;
    }

    &__col {
        width: 100%;

        @include desktop {
            width: calc(50% - 15px);
        }
    }

    &__img {
        margin: 25px 0 0 0;

        @include tablette {
            margin-right: auto;
            margin-left: auto;
        }
    }

    &__slider {
        padding-top: 15px;
        transition-timing-function: $custom_ease;

        &Content {
            position: relative;
            margin: 0 0 40px 0;
            padding: 30px;
            border: 1px solid color(main);
            font-size: 2rem;
            line-height: 1.5;

            svg {
                left: 30px;

                path {
                    fill: color(main);
                }
            }

            &--green {
                background: color(alt);
                color: color(blanc);

                svg {
                    path {
                        fill: color(noir);
                    }
                }
            }

            &--beige {
                background: color(main);

                svg {
                    path {
                        fill: color(alt);
                    }
                }
            }

            &--dark {
                background: color(noir);
                color: color(blanc);

                svg {
                    path {
                        fill: color(alt);
                    }
                }
            }

            @include desktop {
                &:nth-child(2) {
                    transform: translate(90px, 0);
                }
            }
        }

        &Item {
            opacity: 0;
            transition: opacity 0.2s ease-out;
            pointer-events: none;

            &.tns-slide-active,
            &.tns-slide-active ~ .reaction__sliderItem {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 30px;
    }

    .title {
        margin: 0 0 40px 0;
        font-weight: 400;
        text-align: center;

        @include desktop {
            margin: 0 0 90px 0;
        }
    }

    .tns-ovh {
        overflow: visible;
    }

    .tns-controls {
        display: flex;
        position: relative;
        z-index: 1;
        justify-content: center;
        column-gap: 20px;

        button {
            background: transparent;
            cursor: pointer;

            &[disabled] {
                opacity: 0.3;
                cursor: auto;
            }
        }
    }
}

.anchor {
    padding: 40px 0;

    @include desktop {
        padding: 80px 0;
    }

    &__col {
        width: 100%;

        @include desktop {
            width: calc(100% / 3 * 1 - 10px);
        }

        &--content {
            display: flex;
            align-items: center;
        }
    }

    &__img {
        width: 100%;
    }

    &__link {
        display: block;
        padding: 10px 0;
        transition: color 0.3s ease-out;
        color: color(noir);
        font-family: $title;
        font-size: 2.4rem;
        font-weight: 700;
        text-align: center;

        @include desktop {
            &:hover {
                color: color(alt);
            }
        }
    }

    &__list {
        width: 100%;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
    }
}

.video,
.doc,
.link {
    padding: 40px 0;

    &__figure {
        position: relative;
        flex: 0 0 350px;
        margin: 0 0 0 20px;
        cursor: pointer;

        @include tablette {
            flex: 1 0 100%;
            margin: 20px 0 0 0;
        }

        &Img {
            width: 100%;
        }

        svg {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0 0 0;
        padding: 20px 30px;
        background: color(main);

        @include desktop {
            margin-left: 95px;
        }

        @include tablette {
            flex-wrap: wrap;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        margin: 20px 0 0 0;
        color: color(noir);

        @include desktop {
            margin-left: 95px;
        }

        svg {
            display: block;
            flex: 0 0 20px;
            height: auto;
            margin: 0 10px 0 0;
        }
    }

    &__picto {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        column-gap: 30px;
        margin: 30px 0;
    }

    &__img {
        display: block;
        width: auto;
        max-height: 65px;
        margin: 0 0 20px 0;
    }

    &__text {
        display: flex;
        align-items: center;

        svg {
            display: block;
            flex: 0 0 20px;
            height: auto;
            margin: 0 10px 0 0;
        }
    } 

    &__title {
        margin: 0 0 10px 0;

        svg {
            display: inline-block;
            width: auto;
            height: 50px;
            margin: -9px 35px 0 0;
            vertical-align: top;

            @include mobile {
                width: 30px;
                height: auto;
                margin: -7px 15px 0 0;
            }
        }
    }
}

.programme {
    padding: 40px 0;
    background: color(main);

    @include desktop {
        padding: 80px 0;
    }

    &__picto {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        column-gap: 50px;
    }

    &__img {
        display: block;
        margin: 0 0 20px 0;
    }

    &__text {
        margin: 40px 0;
        text-align: center;
    }

    svg,
    &__image {
        display: block;
        width: 100%;
        max-width: 255px;
        margin: 0 auto;
    }
}